/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0e2cdb25-9bab-4054-9c4e-c5345c1df321",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4a3pvs0Gz",
    "aws_user_pools_web_client_id": "2fck1sqio3ft2gbclhsu42200n",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://7dvnzazo7c.execute-api.us-east-1.amazonaws.com/fortia",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://exa5ojrvcbdu5hbjcsytqceiri.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fik4x5o5rbehna2734ajvvw7xu",
    "aws_user_files_s3_bucket": "bama-posda49b493d55545e49c1552c7c11b7bfffortia-fortia",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
